import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from "react-scroll";
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import Avatar from '@mui/material/Avatar';


const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  maxWidth: 400,
  color: theme.palette.text.primary,
  border: 0,
  boxShadow: 'none',
}));

export default function Footer() {

    function Copyright(props) {
        return (
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <a color="inherit" href="https://dingteala1.com/">
              Ding Tea Los Angeles
            </a>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }


    return(
        <Container
        maxWidth=""
        component="footer"
        sx={{
        py: [3, 6],
        backgroundImage: 'linear-gradient(to bottom, #ffffff, #fefefe, #fcfdfc, #fbfbfb, #fafafa, #f6f6f8, #f2f3f6, #edeff4, #e3e8f1, #d8e2ee, #cbddea, #bed7e5)',
        }}
        >

          <Grid container spacing={2} columns={16}>
            <Grid item xs={16} md={8} lg={8} xl={8}>
              
                <List 
                style={{
                  float: 'right', 
                  letterSpacing: '.2rem', 
                  fontWeight: 800
                }}>
                    <ListItem style={{display: 'block', color: '#049dc7', cursor: 'pointer', textAlign: 'right'}}>
                      <Link
                        className='active'
                        to="home"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}>
                            Home
                        </Link>
                    </ListItem>

                    <ListItem style={{display: 'block', color: '#049dc7', cursor: 'pointer', textAlign: 'right'}}>
                      <Link
                        className='active'
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}>
                            About
                      </Link>
                    </ListItem>

                    <ListItem style={{display: 'block', color: '#049dc7', cursor: 'pointer', textAlign: 'right'}}>
                      <Link
                        className='active'
                        to="event"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}>
                        Event
                      </Link>
                    </ListItem>

                    <ListItem style={{display: 'block', color: '#049dc7', cursor: 'pointer', textAlign: 'right'}}>
                    <Link
                      className='active'
                      to="gallery"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}>
                      Gallery
                    </Link>
                    </ListItem>

                    <ListItem style={{display: 'block', color: '#049dc7', cursor: 'pointer', textAlign: 'right'}}>
                      <Link
                      className='active'
                      to="team"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}>
                      Team
                    </Link>
                    </ListItem>

                    <ListItem style={{display: 'block', color: '#049dc7', cursor: 'pointer', textAlign: 'right'}}>
                    <Link
                      to="location"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}>
                          Location
                      </Link>
                    </ListItem>

                    <ListItem className='visited_link' style={{display: 'block', cursor: 'pointer', textAlign: 'right'}}>
                      <a href="/career" className='visited_link' style={{textDecoration: 'none'}}>Career</a>
                    </ListItem>
                </List>
              
            </Grid>
            <Divider orientation="vertical" flexItem>
            </Divider>
            <Grid item xs={16} md={6} lg={6} xl={6}>
              <h1 style={{
              "fontSize":"2rem",
              "fontWeight":"600",
              "letterSpacing":"-2.5px",
              "fontFamily":"cursive",
              "lineHeight":"4rem"}}
              >
                Ding Tea Los Angeles 
            </h1>
            <StyledPaper
              sx={{
                my: 1,
                p: 1,
              }}
            >
              <Grid container wrap="nowrap" spacing={2} style={{marginLeft: 0}}>
                <Grid item style={{ paddingLeft: 0}}>
                  <Avatar sx={{ width: 30, height: 30,  bgcolor: '#FFFFFF', color: '#000000', boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px' }}><LocationOnIcon /></Avatar>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography noWrap style={{fontWeight: 400}}>
                  1415 E Gage Ave, Los Angeles, <br />
                  CA 90001, USA
                  </Typography>
                </Grid>
              </Grid>
            </StyledPaper>
            <StyledPaper
              sx={{
                my: 1,
                p: 1,
              }}
            >
              <Grid container wrap="nowrap" spacing={2} style={{marginLeft: 0}}>
                <Grid item style={{ paddingLeft: 0}}>
                  <Avatar sx={{ width: 30, height: 30,  bgcolor: '#FFFFFF', color: '#000000', boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px' }}><EmailIcon /></Avatar>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography noWrap style={{fontWeight: 400}}>
                      <a href = "mailto: dingtealosangeles@gmail.com" style={{textDecoration: 'none'}}>dingtealosangeles@gmail.com</a>
                    </Typography>
                </Grid>
              </Grid>
            </StyledPaper>
            <StyledPaper
              sx={{
                my: 1,
                p: 1,
              }}
            >
              <Grid container wrap="nowrap" spacing={2} style={{marginLeft: 0}}>
                <Grid item style={{ paddingLeft: 0}}>
                  <Avatar sx={{ width: 30, height: 30,  bgcolor: '#FFFFFF', color: '#000000', boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px' }}><CallIcon /></Avatar>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography noWrap style={{fontWeight: 400}}>
                    <a href="tel:+13234849264" style={{textDecoration: 'none'}}>+1 323-484-9264</a> 
                    </Typography>
                </Grid>
              </Grid>
            </StyledPaper>
            </Grid>
          </Grid>
       
        <Copyright sx={{ mt: 5 }} />
        </Container>
    );
}
    