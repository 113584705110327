import React from 'react';
import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const itemData = [
  {
    img: 'https://baldrove.sirv.com/dingtea_la/IMG-20221015-WA0028.jpg',
    title: 'Team',
  },
  {
    img: 'https://baldrove.sirv.com/dingtea_la/IMG-20221015-WA0030.jpg',
    title: 'Team',
  },
];

function Team(){
  return(
    <div>
        <Grid container
          sx={{
            my: 2,
            mx: 'auto',
            p: 1,
          }}
          maxWidth="lg"
          >
          <Grid item xs={12} xl={12} lg={8} md={12} sx={{padding: 2}} style={{minHeight: '300PX', textAlign: 'center', backgroundColor: '#FFFFFF', borderRadius: '40px 40px 0 0', boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px'}}>
          <Typography variant="h5" gutterBottom component="div" style={{fontWeight: 600}}>
            Our Professional Team
          </Typography><Divider />

            <Typography variant="subtitle1" gutterBottom component="div" style={{padding: '30px'}}>
            We've been told "Happy Staff = Happy Boba Drinks". We value all our team members and appreciate them for all the hard work and dedication to pumping out amazing drinks day in day out!
             </Typography>

             <ImageList sx={{ width: 'auto', height: 450 }} cols={2} rowHeight={164}>
              {itemData.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${item.img}`}
                    srcSet={`${item.img}`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      </div>
  );
}

export default Team;
