import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { Link } from "react-scroll";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';


export default function Header(){
  const [checked, setChecked] = React.useState(false);
  const containerRef = React.useRef(null);
  const [isActiveSmMenu, setActiveSmMenu] = React.useState("false");

  const handleNavigation = (event) => {
      setActiveSmMenu(!isActiveSmMenu);
      setChecked((prev) => !prev);
  }
    return(
        <Container maxWidth="" 
        sx={{ 
          backgroundColor: '#ffffff',
          margin: 0,
          padding: 0,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 999,
          boxShadow: '0 2px 24px 0 rgb(0 0 0 / 15%)',
          animation: '500ms ease-in-out 0s normal none 1 running fadeInDown',
          paddingTop: 0,
          paddingBottom: 0,
          }}>
          <AppBar
          position="static"
          color=""
          elevation={0}
          sx={{ borderBottom: (theme) => `0px solid ${theme.palette.divider}` }}
        >
          <Toolbar sx={{ flexWrap: 'wrap', padding: 0, minHeight: 0, backgroundColor: '#ffffff' }}>
            <Typography variant="div" color="inherit" noWrap sx={{ flexGrow: 1, padding: '10px' }}>
            <a href='/'>
            <img
              alt="Remy Sharp"
              src="https://baldrove.sirv.com/dingtea_la/logo.png"
              style={{ 
                width: 56,
                height: 56,
              }}
            />
            </a>
            </Typography>
            <Button href="https://www.clover.com/online-ordering/dingteala" variant="contained" size="small" sx={{ my: 1, mx: 1.5, fontWeight: 800, color: '#FFFFFF', background: 'linear-gradient(to bottom, #f06e88, #e36d91, #d56d98, #c56e9d, #b56f9f, #a977b1, #9881bf, #838ac9, #6b9bc6, #589fdd, #00c5da, #0fd4c3)', textShadow: '0px 3px 3px #710140' }}>
              Order and pickup
            </Button>
          </Toolbar>
        </AppBar>

        <Grid id="secondary_nav">
          <div>
              <Grid container justifyContent="right" style={{background: 'transparent', position: 'fixed'}}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleNavigation}
                  sx={{ mr: 2, borderRadius: 0, backgroundColor: '#FFFFFF', marginTop: '10px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}
                >
                <MenuIcon />
              </IconButton>
              </Grid>
              <Slide direction="up" in={checked} container={containerRef.current}>
              <div className={isActiveSmMenu ? 'NavHidden' : 'NavActive'}>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                >
                  <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
                    <Link
                        className='active'
                        onClick={handleNavigation}
                        to="home"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}>
                            Home
                        </Link>
                  </Button>
                  <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
                    <Link
                        className='active'
                        onClick={handleNavigation}
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}>
                            About
                      </Link>
                  </Button>
                  <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
                  <Link
                    className='active'
                    onClick={handleNavigation}
                    to="event"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}>
                    Event
                  </Link>
                  </Button>
                  <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
                  <Link
                    className='active'
                    onClick={handleNavigation}
                    to="gallery"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}>
                    Gallery
                  </Link>
                  </Button>
                  <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
                  <Link
                    className='active'
                    onClick={handleNavigation}
                    to="team"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}>
                    Team
                  </Link>
                  </Button>
                  <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
                    <Link
                      onClick={handleNavigation}
                      to="location"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}>
                          Location
                      </Link>
                  </Button>
                  <Button href="/career" sx={{fontWeight: 800, fontSize: '1rem'}}>Career</Button>
                  </Stack>
              </div>
              </Slide>
          </div>
        </Grid>

        <Grid id="primary_nav" container justifyContent="center">
        <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
        >
          <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}>
                    Home
                </Link>
          </Button>
          <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
              <Link
                className='active'
                onClick={handleNavigation}
                to="about"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}>
                About
              </Link>
            </Button>
          <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
              <Link
                className='active'
                onClick={handleNavigation}
                to="event"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}>
                Event
              </Link>
          </Button>
          <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
              <Link
                className='active'
                onClick={handleNavigation}
                to="gallery"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}>
                Gallery
              </Link>
          </Button>
          <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
              <Link
                className='active'
                onClick={handleNavigation}
                to="team"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}>
                Team
              </Link>
          </Button>
          <Button sx={{fontWeight: 800, fontSize: '1rem'}}>
            <Link
              activeClass="active"
              to="location"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}>
                  Location
              </Link>
          </Button>
          <Button href="/career" sx={{fontWeight: 800, fontSize: '1rem'}}>Career</Button>
          </Stack>
        </Grid>
        </Container>
    );
}