import React, { useState, useCallback } from "react";
import { Grid } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';



function Gallery(){
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    "https://baldrove.sirv.com/dingtea_la/gallery/la/la-1.jpg",
    "https://baldrove.sirv.com/dingtea_la/gallery/la/20220622_170504.jpg",
    "https://baldrove.sirv.com/dingtea_la/gallery/la/20220626_132712.jpg",
    "https://baldrove.sirv.com/dingtea_la/gallery/la/20220626_173123.jpg",
    "https://baldrove.sirv.com/dingtea_la/gallery/gallery-2.jpg",
    "https://baldrove.sirv.com/dingtea_la/gallery/gallery-10.jpg",
    "https://baldrove.sirv.com/dingtea_la/gallery/gallery-13.jpg",
    "https://baldrove.sirv.com/dingtea_la/gallery/gallery-3.jpg",
    "https://baldrove.sirv.com/dingtea_la/gallery/gallery-4.jpg",
    "https://baldrove.sirv.com/dingtea_la/gallery/gallery-7.jpg"
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div>
      <Grid container
          sx={{
            my: 2,
            mx: 'auto',
            p: 1,
          }}
          maxWidth="lg"
          >
          <Grid item xs={12} xl={12} lg={12} md={12} sx={{padding: 2}} >
          <Box sx={{ width: '100%', maxHeight: 700, overflowY: 'scroll' }}>
            <ImageList variant="masonry" cols={4} rows={10} gap={10}>
              {images.map((src, index) => (
                <ImageListItem key={index}>
                    <img
                      src={src}
                      onClick={() => openImageViewer(index)}
                      key={index}
                      style={{ margin: "2px" }}
                      alt=""
                    />
                </ImageListItem>
                ))}
                
                  {isViewerOpen && (
                    <ImageViewer
                      src={images}
                      currentIndex={currentImage}
                      onClose={closeImageViewer}
                      disableScroll={false}
                      backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                      }}
                      closeOnClickOutside={true}
                    />
                  )}
            </ImageList>
          </Box>
          </Grid>
        </Grid>
    </div>
  );
}

export default Gallery;
