import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Hero from '../src/image/hero_500px.png';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import PublicIcon from '@mui/icons-material/Public';
import Avatar from '@mui/material/Avatar';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import About from './About';
import Event from './Event';
import Gallery from './Gallery';
import Team from './Team';
import Map from './Map';
import Button from '@mui/material/Button';



const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  maxWidth: 400,
  color: theme.palette.text.primary,
  border: 0,
  boxShadow: 'none',
}));


export default function Contents() {

  return (
    <Container maxWidth="" sx={{ background: '#f8f9fa url(https://baldrove.sirv.com/dingtea_la/bg-image.png) no-repeat top center', margin: 0, padding: 2, paddingTop: '150px', backgroundSize: 'cover', minHeight: '600px' }}>
       
      <section id="home">
      <Box>
       <Grid container spacing={3}>
        <Grid item xs={12} xl={3} lg={3}>
          <h1 className='handelMobileScreen'>
              Ding Tea Los Angeles 
          </h1>
          <Typography variant="subtitle1" gutterBottom component="div">
            Ding Tea Los Angeles is a very popular bubble tea store in Los Angeles  is also known as <strong>'Ding Tea Los Angeles LA' </strong>
            which serves quality type Boba tea.
          </Typography>
          <Box style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <img src="https://baldrove.sirv.com/dingtea_la/clover_qr_code.png" alt="weeklyOperation" style={{maxWidth: '200px', height: 'auto', mx: 'auto'}}></img>
          </Box>
          
          <Button href="https://www.clover.com/online-ordering/dingteala" variant="contained" size="large" sx={{ my: 1, mx: 1.5, fontWeight: 800, width: '90%', color: '#FFFFFF', background: 'linear-gradient(to bottom, #f06e88, #e36d91, #d56d98, #c56e9d, #b56f9f, #a977b1, #9881bf, #838ac9, #6b9bc6, #589fdd, #00c5da, #0fd4c3)', textShadow: '0px 3px 3px #710140' }}>
              Order and pickup
            </Button>
        </Grid>
        <Grid item xs={12} xl={6} lg={6} style={{position: 'relative'}}>
        <Box style={{
          backgroundImage: `url(${Hero})`,
          backgroundSize: "contain",
          color: "#f5f5f5",
          maxHeight: '445px',
          maxWidth: '400px',
          height: '416px',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: 'auto',
          backgroundRepeat: 'no-repeat',
          }}>
             <Stack direction="row" spacing={1} 
             style={{
              position: 'absolute', 
              bottom: '0px', 
              backgroundColor: '#FFFFFF', 
              padding: '10px', 
              borderRadius: '30px',
              boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
              }}>
              <Chip color="info" size="lg" icon={<FacebookRoundedIcon style={{marginLeft: '12px', marginRight: '-11px'}} />} component="a" href="https://www.facebook.com/dingtealosangeles-104214462258625" clickable />
              <Chip color="info" size="lg" icon={<InstagramIcon style={{marginLeft: '12px', marginRight: '-11px'}} />} component="a" href="https://www.instagram.com/dingtealosangeles" clickable />
              <Chip color="info" size="lg" icon={<PublicIcon style={{marginLeft: '12px', marginRight: '-11px'}} />} component="a" href="https://dingteala1.com/" clickable />
            </Stack>
        </Box>
        </Grid>
        <Grid item xs={12} xl={3} lg={3} style={{marginTop: '50px'}}>
        <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
        }}
      >
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item style={{ paddingLeft: 0}}>
            <Avatar sx={{ width: 55, height: 55,  bgcolor: '#FFFFFF', color: '#000000', boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px' }}><EmojiFoodBeverageIcon /></Avatar>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography noWrap style={{fontWeight: 600}}>Proper combination</Typography>
            <small>We give customers the option of choosing the preferred amount of ice or sugar in their drinks. The sugar level is usually specified in percentages (e.g. 25%, 50%, 75%, 100%), and the ice level is usually specified (e.g. no ice, less ice, normal ice).</small>
          </Grid>
        </Grid>
      </StyledPaper>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
        }}
      >
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item style={{ paddingLeft: 0}}>
            <Avatar sx={{ width: 55, height: 55,  bgcolor: '#FFFFFF', color: '#000000', boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px' }}><RestaurantMenuIcon /></Avatar>
          </Grid>
          <Grid item xs>
            <Typography style={{fontWeight: 600}}>Many Flavors & Toppings to choose</Typography>
            <small>There are so many boba tea flavors and toppings to create your favourite drink.</small>
          </Grid>
        </Grid>
      </StyledPaper>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
        }}
      >
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item style={{ paddingLeft: 0}}>
            <Avatar sx={{ width: 55, height: 55,  bgcolor: '#FFFFFF', color: '#000000', boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px' }}><EventAvailableIcon /></Avatar>
          </Grid>
          <Grid item xs>
            <Typography style={{fontWeight: 600}}>We host Events, Fundraisers and Catering</Typography>
          </Grid>
        </Grid>
      </StyledPaper>
        </Grid>
      </Grid>
      </Box>
      </section>


      <section id="about" style={{paddingTop: '100px'}}>
      <About />
      </section>

      <section id="event" style={{paddingTop: '100px'}}>
      <Event />
      </section>

      <section id="gallery" style={{paddingTop: '100px'}}>
      <Gallery />
      </section>

      <section id="team" style={{paddingTop: '100px'}}>
      <Team />
      </section>

      <section id="location" style={{paddingTop: '100px'}}>
      <Map />
      </section>

        

    </Container>
  );
}
