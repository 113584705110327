import * as React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Footer from './Footer';
import HeaderNoNav from './HeaderNoNav';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import CircularProgress from '@mui/material/CircularProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2b9ee0',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

function CareerSection(){
  const [disableBtn, setdisableBtn] = React.useState(false);
  const [file, setFile] = React.useState()
  const [fileUploadBtnText, setFileUploadBtnText] = React.useState("Uplaod File");
  const [formSubmitText, setformSubmitText] = React.useState("Submit");
  const [behaveValue, setbehaveValue] = React.useState('Yes');
  const [crimeValue, setCrimeValue] = React.useState('Yes');
  const [HightEducationValue, setHightEducationValue] = React.useState('9th');
  const [permitValue, setPermitValue] = React.useState('No');
  const [identityValue, setIdentityValue] = React.useState('No');
  const [dayNameForShiftOne, setDayNameShiftOne] = React.useState([]);
  const [dayNameForShifTwo, setDayNameShiftTwo] = React.useState([]);


  const shiftOneHander = (event) => {
    const {
      target: { value },
    } = event;
    setDayNameShiftOne(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const shiftTwoHander = (event) => {
    const {
      target: { value },
    } = event;
    setDayNameShiftTwo(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  
  const FIleUploadHander = (event) => {
    setFileUploadBtnText("File Attached!");
    setFile(event.target.files[0])
  };


  
  const identityHandle = (event) => {
    setIdentityValue(event.target.value);
  };

  const permitHandle = (event) => {
    setPermitValue(event.target.value);
  };

  const HightEducationHandle = (event) => {
    setHightEducationValue(event.target.value);
  };

  const behaveHandle = (event) => {
    setbehaveValue(event.target.value);
  };

  const crimeHandle = (event) => {
    setCrimeValue(event.target.value);
  };

  const maxNumber = 999999;
  const randomNumber = Math.floor((Math.random() * maxNumber) + 1);

  const handleSubmit = (event) => {
    event.preventDefault();
    setformSubmitText("Please wait!");
    setdisableBtn(true);
    const data = new FormData(event.currentTarget);

    data.append('attachment', file);
    data.append('fName', data.get('firstName'));
    data.append('lName', data.get('lastName'));
    data.append('cell', data.get('phone'));
    data.append('email', data.get('email'));
    data.append('psbl_date', data.get('available_date'));
    data.append('abailability', data.get('availability'));
    data.append('working_hours', data.get('availability_weekly'));
    data.append('routine', 'Morning: ' + data.get('morning_shift') + ' | Evening: ' + data.get('evening_shift'));
    data.append('wage', data.get('wage'));
    data.append('proof', data.get('permit'));
    data.append('identity', data.get('identity'));
    data.append('social_hearing', data.get('hear'));
    data.append('highSchool', data.get('highSchool'));
    data.append('state', data.get('state'));
    data.append('highestGrade', data.get('HightEducation'));
    data.append('work_his', data.get('previous_work'));
    data.append('work_duration', data.get('work_duration'));
    data.append('working_position', data.get('position'));
    data.append('leaving_reasons', data.get('leaving_reason'));
    data.append('mana_name', data.get('man_name'));
    data.append('canContact', data.get('can_contact'));
    data.append('highLights', data.get('highlight'));
    data.append('extraordinary', data.get('extraordinary'));
    data.append('lateReasons', data.get('lateReason'));
    data.append('hobby', data.get('hobby'));
    data.append('special_edu', data.get('training'));
    data.append('crimeReport', data.get('crime'));
    data.append('property', data.get('behave'));
    data.append('application_id', randomNumber);

            
            axios.post('https://api.dingteala1.com/api.php', data)
              .then(
                  response => {
                    alert(JSON.stringify(response.data.message));
                    setformSubmitText("Submit");
                    setdisableBtn(false);
                    window.location.reload();
                  }
                  )
              .catch(error => {
                  console.log("ERROR:: ",error.response.data);
                  setformSubmitText("Submit");
                  });
  
  }

    return(
        <section id='career' style={{background: '#f8f9fa' }}>
            <ThemeProvider theme={theme}>
              <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                  sx={{
                    paddingTop: 15,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography component="h1" variant="h5">
                    TEAM MEMBERS
                  </Typography>
                  <Typography component="div" variant="subtitle1" style={{textAlign: 'center'}}>
                    ESSENTIAL DUTIES AND RESPONSIBILITIES
                  </Typography><br></br>

                  <List>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowRightAltIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Greet customers and make them feel welcome by getting to know them." />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <ArrowRightAltIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Provide first class service by developing enthusiastically loyal customers all of the time
                        (that's just fancy for treat our customers well)." />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <ArrowRightAltIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Prepare all products according to company guidelines consistently, accurately, and efficiently." />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <ArrowRightAltIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Clean and maintain the front and back of the store." />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <ArrowRightAltIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Take orders and complete transactions according to cash handling procedures
                        (that means you better not steal or we'll tell on you)." />
                  </ListItem>
                  </List>
                  <br></br>
                  <br></br>

                  <Typography component="div" variant="subtitle1">
                    WE'RE LOOKING FOR PEOPLE WHO
                  </Typography><br></br>

                  <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Possess 1 year or more of management or leadership experience in a professional setting" />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Have unsurpassed communication skills" />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Are not afraid to speak in front of groups" />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Are able to coach and encourage team members to succeed by providing positive feedback" />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Aren't afraid to correct and effectively communicate with team members not performing to standard" />
                  </ListItem>
                  </List><br></br>


                  <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 4 }} style={{width: '95%' }}>
                  <Typography variant="h4" gutterBottom component="div">
                    Basic Information
                    </Typography>
                    <Divider />

                    <Grid container spacing={3} style={{marginTop: '10px'}}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="firstName"
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          autoComplete="family-name"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="phone"
                          label="Phone number"
                          name="phone"
                          autoComplete="phone"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="available_date"
                          name='available_date'
                          label="Date Available *"
                          type="date"
                          defaultValue="0000-00-00"
                          sx={{ width: 220 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                      <InputLabel>Availability *</InputLabel>
                        <FormControlLabel
                          control={<Checkbox value="Full Time" id='availability' name='availability' color="primary" />}
                          label="Full Time"
                        />
                        <FormControlLabel
                          control={<Checkbox value="Part Time" id='availability' name='availability' color="primary" />}
                          label="Part Time"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="availability_weekly"
                          label="How many hours can you work weekly? "
                          id="availability_weekly"
                          autoComplete="availability_weekly"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Please select what day & time you're available to work *</InputLabel>
                            <FormControl sx={{ marginTop: '15px' }} fullWidth="true">
                              <InputLabel id="morning_shift">9:30 am to 5pm</InputLabel>
                              <Select
                                labelId="morning_shift"
                                id="morning_shift"
                                name='morning_shift'
                                multiple
                                value={dayNameForShiftOne}
                                onChange={shiftOneHander}
                                input={<OutlinedInput label="9:30 am to 5pm" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                              >
                                {days.map((day) => (
                                  <MenuItem key={day} value={day}>
                                    <Checkbox checked={dayNameForShiftOne.indexOf(day) > -1} />
                                    <ListItemText primary={day} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <FormControl sx={{ marginTop: '15px' }} fullWidth="true">
                              <InputLabel id="evening_shift">5pm to 10pm</InputLabel>
                              <Select
                                labelId="evening_shift"
                                id="evening_shift"
                                name='evening_shift'
                                multiple
                                value={dayNameForShifTwo}
                                onChange={shiftTwoHander}
                                input={<OutlinedInput label="9:30 am to 5pm" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                              >
                                {days.map((day) => (
                                  <MenuItem key={day} value={day}>
                                    <Checkbox checked={dayNameForShifTwo.indexOf(day) > -1} />
                                    <ListItemText primary={day} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="wage"
                          label="Preferred Wage"
                          id="wage"
                          autoComplete="wage"
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                      <br></br>
                      <Typography variant="h4" gutterBottom component="div">
                        Work Eligibility
                      </Typography>
                        <Divider />
                        

                      <Grid container spacing={2} style={{marginTop: '10px'}}>
                        <Grid item xs={12}>

                          <FormControl>
                              <FormLabel id="permit">If hired, and you are under 18, can you furnish proof of age and/or a work permit? *</FormLabel>
                                <RadioGroup
                                  aria-labelledby="permit"
                                  name="permit"
                                  value={permitValue}
                                  onChange={permitHandle}
                                >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                <FormControlLabel value="I am over 18" control={<Radio />} label="I am over 18" />
                              </RadioGroup>
                            </FormControl>
                           
                        </Grid>

                          <Grid item xs={12}>
                            
                            <FormControl>
                              <FormLabel id="identity">If hired, can you provide proof of identity and authorization to work in the US? *</FormLabel>
                              <RadioGroup
                                aria-labelledby="identity"
                                name="identity"
                                value={identityValue}
                                onChange={identityHandle}
                              >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>

                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="hear"
                              label="How did you hear about this position?"
                              id="hear"
                              autoComplete="hear"
                            />
                          </Grid>
                      </Grid>           
                      

                      <br></br>  
                      <br></br>
                        <Typography variant="h4" gutterBottom component="div">
                          Education
                        </Typography>
                          <Divider />
                      

                      <Grid container spacing={2} style={{marginTop: '10px'}}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="highSchool"
                            label="High School *"
                            id="highSchool"
                            autoComplete="highSchool"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="state"
                            label="City/State *"
                            id="state"
                            autoComplete="state"
                          />
                        </Grid>

                         <Grid item xs={12}>
                            <FormControl>
                              <FormLabel id="HightEducation">Highest Grade Completed *</FormLabel>
                                <RadioGroup
                                  aria-labelledby="HightEducation"
                                  name="HightEducation"
                                  id='HightEducation'
                                  value={HightEducationValue}
                                  onChange={HightEducationHandle}
                                >
                                <FormControlLabel value="9th" control={<Radio />} label="9th" />
                                <FormControlLabel value="10th" control={<Radio />} label="10th" />
                                <FormControlLabel value="11th" control={<Radio />} label="11th" />
                                <FormControlLabel value="12th" control={<Radio />} label="12th" />
                              </RadioGroup>
                            </FormControl>
                        </Grid>
                      </Grid>
                      <br></br>
                        <br></br>
                        <Typography variant="h4" gutterBottom component="div">
                          Your Work History
                        </Typography>
                          <Divider />
                         
                        <Grid container spacing={2} style={{marginTop: '10px'}}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="previous_work"
                            label="Company Name #1"
                            id="previous_work"
                            autoComplete="previous_work"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="work_duration"
                            label="From (mm/yyyy) to (mm/yyyy)"
                            id="work_duration"
                            autoComplete="work_duration"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="position"
                            label="Position"
                            id="position"
                            autoComplete="position"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="leaving_reason"
                            label="Reason for Leaving"
                            id="leaving_reason"
                            autoComplete="leaving_reason"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="man_name"
                            label="Manager Name"
                            id="man_name"
                            autoComplete="man_name"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="can_contact"
                            label="May We Contact?"
                            id="can_contact"
                            autoComplete="can_contact"
                          />
                        </Grid>

                        <Grid item xs={12}>
                        <FormLabel id="file">Add More Work History (File size less than 4 MB) </FormLabel><br></br>
                        <FormLabel id="file">Upload your resume</FormLabel><br></br><br></br>
                        
                        <Button
                            id='fileUplaodBtn'
                            variant="contained"
                            component="label"
                            style={{background: '#dc6d94', color: '#FFFFFF'}}
                          >
                            {fileUploadBtnText}
                            <input
                              id='file'
                              name='file'
                              type="file"
                              onChange={FIleUploadHander}
                              hidden
                            />
                          </Button>
                        </Grid>
                      </Grid>
                      <br></br>
                        <br></br>
                        <Typography variant="h4" gutterBottom component="div">
                          Your Work History
                        </Typography>
                          <Divider />
                        
                        <Typography variant="subtitle1" gutterBottom color="primary" component="div">
                          When responding to the following questions, you may omit information, which would disclose race, gender, religion, creed, national origin, or disability.
                        </Typography>
                        
                        <Grid container spacing={2} style={{marginTop: '10px'}}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="highlight"
                            label="Highlights of Qualifications"
                            id="highlight"
                            autoComplete="highlight"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="extraordinary"
                            label="Describe a time when you gave extraordinary service? *"
                            id="extraordinary"
                            autoComplete="extraordinary"
                            multiline
                            rows={4}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="lateReason"
                            label="Describe a time when you were late? What happened? What did you do? *"
                            id="lateReason"
                            autoComplete="lateReason"
                            multiline
                            rows={4}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="hobby"
                            label="What hobbies do you enjoy? *"
                            id="hobby"
                            autoComplete="hobby"
                            multiline
                            rows={2}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="training"
                            label="Other special training or education"
                            id="training"
                            autoComplete="training"
                          />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl>
                              <FormLabel id="crime">During the past seven years, have you ever been convicted or pled guilty to a felony? *</FormLabel>
                                <RadioGroup
                                  aria-labelledby="crime"
                                  name="crime"
                                  value={crimeValue}
                                  onChange={crimeHandle}
                                >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                           <FormControl>
                              <FormLabel id="behave">Have you ever taken any merchandise, money, or property from an employer? *</FormLabel>
                                <RadioGroup
                                  aria-labelledby="behave"
                                  name="behave"
                                  value={behaveValue}
                                  onChange={behaveHandle}
                                >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox value="allowExtraEmails" color="primary" />}
                          label="I Read and Accept Terms and Conditions"
                        />
                      </Grid>
                      <input name="application_id" value="123456" id='123456' type="hidden" />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, color: '#FFFFFF' }}
                      disabled={disableBtn}
                    >
                      {
                        disableBtn ? <CircularProgress style={{color: '#ffffff', height: '30px', width: '30px', padding: '5px' }} />  : ''
                      }
                      {formSubmitText}
                    </Button>
                  </Box>
                </Box>
              </Container>
            </ThemeProvider>
        </section>
    );
}

function Layout() {
    return (
      <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <CssBaseline />
        <HeaderNoNav />
        <CareerSection />
        <Footer />
      </React.Fragment>
      </ThemeProvider>
    );
  }

  export default function Career() {
    return <Layout />;
  }