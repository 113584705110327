import axios from 'axios';
import React from 'react';
import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';



function Event(){
  const [disableBtn, setdisableBtn] = React.useState(false);
  const [formSubmitText, setformSubmitText] = React.useState("Submit");


  const handleSubmit = (event) => {
    event.preventDefault();
    setformSubmitText("Please wait!");
    setdisableBtn(true);

    const data = new FormData(event.currentTarget);

    axios.post('https://dingteawcla.com/api/event-submit', data)
      .then(
        response => {
          alert(JSON.stringify(response.data.success));
          setformSubmitText("Submit");
          setdisableBtn(false);
        }
      )
      .catch(error => {
        console.log("ERROR:: ", error.response.data);
        setformSubmitText("Submit");
      });
  }
    
  return(
    <div>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <Grid container
          sx={{
            my: 2,
            mx: 'auto',
            p: 1,
          }}
          maxWidth="lg"
          >
          <Grid item xs={12} xl={8} lg={8} md={8} sx={{padding: 2}} style={{minHeight: '300PX', textAlign: 'center', backgroundColor: '#FFFFFF', borderRadius: '0 0 0 0', boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px'}}>
          <Typography variant="h5" gutterBottom component="div" style={{fontWeight: 600}}>
            We host Events, Fundraisers and Catering
          </Typography><Divider />

            <Typography variant="subtitle1" gutterBottom component="div" style={{padding: '30px'}}>
              We love our community! We've worked with many local schools and clubs to offer fundraising. We have hosted weddings and many cupsleeves events! Would you like us to bring Dingtea to your next school or catering event? Contact us!
            </Typography>
          </Grid>

          <Grid className='special-grid' item xs={12} xl={4} lg={4} md={4} sx={{padding: 2}} style={{minHeight: '300PX', textAlign: 'center', backgroundColor: '#FFFFFF', borderRadius: '0 0 0 0', boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px'}}>
            <Typography variant="h5" gutterBottom component="div" style={{fontWeight: 600}}>
              Register Your Events
            </Typography><Divider />

              <div style={{paddingTop: '45px'}}>
              <TextField fullWidth label="Event Name" name='eName' size="small" variant="standard" style={{color: "#FFFFFF"}} focused />
              <TextField fullWidth label="Date" size="small" name='date' variant="standard" style={{color: "#FFFFFF"}} />
              <TextField required fullWidth label="Phone Number" size="small" name='cell' variant="standard" style={{color: "#FFFFFF"}} />
              </div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, color: '#FFFFFF' }}
                      disabled={disableBtn}
                    >
                      {
                        disableBtn ? <CircularProgress style={{color: '#ffffff', height: '30px', width: '30px', padding: '5px' }} />  : ''
                      }
                      {formSubmitText}
                    </Button>
          </Grid>
        </Grid>
        </Box>
      </div>
  );
}

export default Event;
