import { Grid } from "@mui/material";

function Map(){
  return(
    <div id="Map">
        <Grid container
          sx={{
            my: 2,
            mx: 'auto',
            p: 1,
          }}>
          <Grid item xs={12} xl={12} lg={12} md={12} sx={{boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px'}}>
              <iframe
                title="Ding tea LA location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5894.849402440389!2d-118.25370184824543!3d33.98297039184903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c925e85a8af3%3A0x492c5adeb68a0e2f!2sDing%20Tea%20Los%20Angeles%20LA!5e0!3m2!1sen!2suk!4v1656078585168!5m2!1sen!2suk"
                style={{ border: "0", width: '100%', height: '350px' }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
          </Grid>
        </Grid>
      </div>
  );
}

export default Map;
