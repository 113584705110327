import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {Buffer} from 'buffer';
import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import weeklyOperation from './image/weekly-operaiton.png';
import StarIcon from '@mui/icons-material/Star';




function About(props){
    const [aboutImage, setaboutImage] = useState([]);
    const [aboutImageLoading, setaboutImageLoading] = useState(true);

    useEffect(() => {
        axios.get('https://baldrove.sirv.com/dingtea_la/gallery/la/la-6.jpg', {
            responseType: 'arraybuffer'
        }).then(resp => {
            const buffer = Buffer.from(resp.data, 'base64');
            setaboutImage(Buffer.from(resp.data, 'binary').toString('base64'));
            setaboutImageLoading(false);
            console.log(buffer);
        });
    }, []);
    
  return(
    <div>
        <Grid container
          sx={{
            my: 2,
            mx: 'auto',
            p: 1,
          }}
          maxWidth="lg"
          >
          <Grid item xs={12} xl={6} lg={6} md={6} sx={{padding: 2}} >
          {aboutImageLoading &&
            (
                <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
            )
          }
          {setaboutImage &&
            (
                <img src={`data:image/jpeg;base64, ${aboutImage}`} alt="Girl in a jacket" style={{width: '100%', height: 'auto', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px' }} />
            )
          }
          </Grid>

          <Grid item xs={12} xl={6} lg={6} md={6} sx={{padding: 2}} >
          <Typography variant="h4" gutterBottom component="div" style={{fontWeight: 600}}>
                Ding Tea Los Angeles
          </Typography>

          <Typography variant="subtitle1" gutterBottom component="div">
            Fresh bubble tea and drinks come from high-quality ingredients. 
            That’s why we choose the ingredients selectively. 
            Our goal is to give you high-quality drinks and a new sensation while drinking bubble tea. 
            We have a variety of teas, coffee and slushies with many flavors. You can even explore the taste of our teas by adding a variety of toppings.
          </Typography>

          <Typography variant="subtitle2" gutterBottom component="div">
            We have tested the flavor of our teas in our kitchen before offering them to you. Our other menus items are also great to try, including juices, coffee, milk tea, yogurt and many
           </Typography><br></br>
           <ul>
                <li> - Best Boba Tea Flavor Combinations</li>
                <li> - Quality Types of Tea for Bubble Tea</li>
                <li> - Types of Milk Bases</li>
           </ul>
          </Grid>
        </Grid>
        <Grid container
          sx={{
            my: 0,
            mx: 'auto',
            p: 0,
          }}
          maxWidth="lg"
          >

          <Grid item xs={12} xl={6} lg={6} md={6} sx={{padding: 1}} style={{minHeight: '300PX', marginTop: '0', borderRadius: '5px 5px 5px 5px'}}>
          <ul className="chat-thread">
            <li>"Love ❤️ thai tea with brown boba here, it was tasty 😋"</li>
            <li>"Love the service fast and friendly!!!"</li>
            <li>"Love the service fast and  friendly!!! Drinks are very delicious."</li>
            <li><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></li>
          </ul>
          </Grid>

          <Grid item xs={12} xl={3} lg={3} md={3} sx={{padding: 1}} style={{minHeight: '300PX', textAlign: 'center', marginLeft: 'auto', marginTop: '0', backgroundColor: '#FFFFFF', borderRadius: '5px 5px 5px 5px', boxShadow: 'rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px'}}>
             <img src={weeklyOperation} alt="weeklyOperation" style={{width: '100%', height: 'auto'}}/>
          </Grid>
          </Grid>
      </div>
  );
}

export default About;
