import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Footer from './Footer';
import Header from './Header';
import Contents from './Contents';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3fbcb5',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});

function Body() {
    return (
      <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <CssBaseline />
        
        <Header />
        <Contents />
        <Footer />


      </React.Fragment>
      </ThemeProvider>
    );
  }

  export default function Canvas() {
    return <Body />;
  }