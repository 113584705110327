import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';

export default function HeaderNoNav(){
    return(
        <Container maxWidth="" 
        sx={{ 
          backgroundColor: '#ffffff',
          margin: 0,
          padding: 0,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 999,
          boxShadow: '0 2px 24px 0 rgb(0 0 0 / 15%)',
          animation: '500ms ease-in-out 0s normal none 1 running fadeInDown',
          paddingTop: 0,
          paddingBottom: 0,
          }}>
          <AppBar
          position="static"
          color=""
          elevation={0}
          sx={{ borderBottom: (theme) => `0px solid ${theme.palette.divider}` }}
        >
          <Toolbar sx={{ flexWrap: 'wrap', padding: 0, minHeight: 0, backgroundColor: '#ffffff' }}>
            <Typography variant="div" color="inherit" noWrap sx={{ flexGrow: 1, padding: '10px', }}>
            <a href='/'>
            <img
              alt="Remy Sharp"
              src="https://baldrove.sirv.com/dingtea_la/logo.png"
              style={{ 
                width: 56,
                height: 56,
              }}
            />
            </a>
            </Typography>
            <Button href="#" variant="contained" size="small" sx={{ my: 1, mx: 1.5, fontWeight: 800, color: '#FFFFFF', background: 'linear-gradient(to bottom, #f06e88, #e36d91, #d56d98, #c56e9d, #b56f9f, #a977b1, #9881bf, #838ac9, #6b9bc6, #589fdd, #00c5da, #0fd4c3)', textShadow: '0px 3px 3px #710140' }}>
              Order and pickup
            </Button>
          </Toolbar>
        </AppBar>
        </Container>
    );
}