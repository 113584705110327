import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Canvas from './Canvas';
import Career from './Career';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
  <React.StrictMode>
    <Routes>
            <Route path="/" element={<Canvas/>}/>
    </Routes>
    
    <Routes>
            <Route path="/career" element={<Career/>}/>
    </Routes>
  </React.StrictMode>
  </Router>
);
